// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/var/lib/jenkins/workspace/robu_corporate_website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/var/lib/jenkins/workspace/robu_corporate_website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ba-100-js": () => import("/var/lib/jenkins/workspace/robu_corporate_website/src/pages/ba100.js" /* webpackChunkName: "component---src-pages-ba-100-js" */),
  "component---src-pages-index-js": () => import("/var/lib/jenkins/workspace/robu_corporate_website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/var/lib/jenkins/workspace/robu_corporate_website/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-solutions-js": () => import("/var/lib/jenkins/workspace/robu_corporate_website/src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

